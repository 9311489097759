import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a855860c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inline-form-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.label), 1),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
      name: "",
      style: _normalizeStyle({'cursor': _ctx.clickable ? 'pointer' : 'auto'}),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, null, 4), [
      [_vModelText, _ctx.innerValue]
    ])
  ]))
}
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="note-edit" style="display: flex; width: 100%; flex-direction: column; gap: 12px;">
    <div class="note-title">
      <img src="@/assets/icons/open-in-new.svg" class="open-in-new-icon" alt="edit">
      <Input
        type="text"
        style="width: 100%;"
        placeholder="TITLE (OPTIONAL)"
        v-model:value="note.title"/>
    </div>
    <div class="note-description">
      <Textarea
        placeholder="Description"
        v-model:value="note.description"
        :style="{width: '100%'}"
      />
    </div>
</div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  computed,
  ref,
} from 'vue';
import { NoteData } from '@/rest-api/companies/assets';
import Textarea from '@/components/ui/TextareaComponent.vue';
import Input from '@/components/ui/InputComponent.vue';

export default defineComponent({
  name: 'NoteEditComponent',
  components: {
    Input,
    Textarea,
  },
  props: {
    note: {
      type: Object as PropType<NoteData>,
      required: true,
    },
  },
  emits: ['update:value', 'click'],
  setup(props, { emit }) {
    const title = ref('');
    const description = ref('');
    const handleClick = (e: MouseEvent) => {
      e.preventDefault();
      emit('click');
    };
    const saveTitle = (_e: Event) => {
      // Save comment logic goes here
      emit('update:value', props.note);
    };
    return {
      title,
      description,
      handleClick,
    };
  },
});
</script>

<style>
.notes .notes-section .note-edit {
  display: flex;
  width: 100%;
}
.notes .notes-section .note-description {
  background: #F4F6FF;
  padding: 16px;
  border-radius: 8px;
}
.notes .notes-section .note-title {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
}

.notes .notes-section .note-title > input {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 14px;
  color: var(--theme-text-color-2);
}

.notes .notes-section .note-title > input::placeholder {
  color: var(--theme-text-color-2);
}

.notes .notes-section .note-title .open-in-new-icon {
  position: absolute;
  top: 18px;
  right: 18px;
}

.notes .notes-section textarea {
  background: none;
  outline: none;
  border: none;
  color: var(--theme-text-color-2);
  display: block;
  height: auto;
  width: 100%;
  font-size: 16px;
  resize: none;
}

.notes .notes-section textarea::placeholder {
  color: var(--theme-text-color-2);
}

</style>

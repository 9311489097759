<template>
  <Modal class="modal__note detail_note" name="add-new-note-modal">
    <div class="header">
      <div class="note-date" style="height: 24px;">
        <p class="date">
          Fri, March 17, 2024
        </p>
      </div>
      <div class="add-title">
        <div class="plus-item">
          <img
          style="border-radius: 50%; border: 1px solid #3348FB; padding: 2px;"
          src="@/assets/icons/plus.svg" alt="Cross">
          <p>
            Add title
          </p>
        </div>
        <Input
        v-if="expandSettings"
        :style="{'padding': '9px 16px', 'color': '#131313'}"/>
      </div>
      <div class="description">
        <Textarea
          placeholder="Enter description"
        />
      </div>
    </div>
    <div class="price-settings" style="width: 100%;">
      <div class="plus-item">
        <img
        style="border-radius: 50%; border: 1px solid #3348FB; padding: 2px;"
        src="@/assets/icons/plus.svg" alt="Cross">
        <p>
          Price settings
        </p>
      </div>
      <div class="price-setting-items" v-if="expandSettings">
        <div style="width: 33%;">
          <FormInput :label="'BRK.B price as of note'"/>
        </div>
        <div style="width: 33%;">
          <FormInput :label="'Your Fair Value Estimate'"/>
        </div>
        <div style="width: 33%;">
          <FormInput :label="'Buy Below Price'"/>
        </div>
      </div>
    </div>
    <div class="add-tags" v-if="expandSettings">
      <div class="plus-item">
        <img
        style="border-radius: 50%; border: 1px solid #3348FB; padding: 2px;"
        src="@/assets/icons/plus.svg" alt="Cross">
        <p>
          Add tags
        </p>
      </div>
      <div class="badge-items" v-if="expandSettings">
        <Badge :label="'BRK.B'" :close="true"/>
        <Badge :label="'NKE'" :close="true" />
        <Badge :label="'COST'" :close="true" />
      </div>
      <Input
      :style="{'padding': '9px 16px', 'color': '#131313'}" v-if="expandSettings" />
    </div>
    <Button
      :loading="loading"
      @click="onClickSave"
    >Add Note</Button>
  </Modal>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  ref,
} from 'vue';
import Textarea from '@/components/ui/TextareaComponent.vue';
import Input from '@/components/ui/InputComponent.vue';
import Modal from '@/components/ModalComponent.vue';
import Badge from '@/components/ui/Badge.vue';
import Button from '@/components/ui/ButtonComponent.vue';
import FormInput from '@/components/ui/FormInputComponent.vue';
import * as api from '@/rest-api/lists';
import store from '@/store';
import { HIDE_MODAL, SHOW_MODAL } from '@/store/actions/application';
import { useStore } from 'vuex';
import { NoteData } from '@/rest-api/companies/assets';
import { CompanyController } from '@/controllers/company/types';

export default defineComponent({
  name: 'AddNewNoteModal',
  components: {
    Modal,
    Input,
    Button,
    Textarea,
    FormInput,
    Badge,
  },
  props: {
    viewType: {
      type: String,
      required: true,
    },
    note: {
      type: Object as PropType<NoteData>,
      required: true,
    },
    company: {
      type: Object as PropType<CompanyController>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const listName = ref('');
    const loading = ref(false);
    const expandSettings = ref(true);
    const onClickSave = () => {
      store.commit(HIDE_MODAL);
    };

    return {
      expandSettings,
      loading,
      listName,
      onClickSave,
    };
  },
});
</script>

<style>
.modal.modal__note .modal-inner {
  max-width: 949px;
  width: 100%;
  align-items: start;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  text-align: start;
}
.modal.modal__note.detail_note .header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.modal.modal__note.detail_note .header .add-title {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.modal.modal__note.detail_note .plus-item {
  display: flex;
  gap: 8px;
}
.modal.modal__note.detail_note .plus-item img{
  border-radius: 50%;
  border: 1px solid #3348FB;
  padding: 2px;
  width: 20px;
  height: 20px;
}
.modal.modal__note.detail_note .plus-item p {
  font-weight: 600;
  color: #3348FB;
  letter-spacing: 2%;
  text-transform: uppercase;
}
.modal.modal__note.detail_note .note-date .date {
  height: 21px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 2%;
  color: #808080;
}
.modal.modal__note input {
  width: 100%;
}
.modal.modal__note.detail_note .description {
  background: #F4F6FF;
  padding: 9px 16px;
  border-radius: 8px;
}
.modal.modal__note.detail_note textarea {
  background: none;
  outline: none;
  border: none;
  font-size: 16px;
  color: #131313;
  display: block;
  height: auto;
  width: 100%;
  resize: none;
}

.modal.modal__note.detail_note textarea::placeholder {
  color: #131313;
}
.modal.modal__note.detail_note .price-settings {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.modal.modal__note.detail_note .price-setting-items {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.modal.modal__note.detail_note .add-tags {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}
.modal.modal__note.detail_note .add-tags .badge-items {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
</style>

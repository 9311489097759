<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="note-card">
    <div class="note" v-if="mode === ''">
      <div class="note-header">
        <div class="title" v-if="note.title && note.title !== ''">{{ note.title }}</div>
        <div class="note-timestamp">{{ createdAt }}</div>
        <div class="note-actions">
          <button class="action-btn" @click="mode = 'edit'">
            <img src="@/assets/icons/edit.svg" alt="Edit">
          </button>
          <button class="action-btn" @click="mode = 'update'">
            <img src="@/assets/icons/refresh.svg" alt="refresh">
          </button>
          <button class="action-btn" @click="mode = 'remove'">
            <img src="@/assets/icons/remove.svg" alt="Remove">
          </button>
        </div>
      </div>
      <p class="note-text"
      style="overflow-wrap: anywhere;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;"
      :style="{
        'line-clamp': expand ? 'none' : 2,
      }">
        {{ note.description }}
      </p>
      <button class="show-more" @click="toggleExpand">
        {{ expand ? 'Show less' : 'Show more'}}
      </button>
    </div>
    <div class="note note-edit-mode" v-if="mode == 'edit'">
      <div class="note-header">
        <input
          type="text"
          v-model="tmpNote.title"
          class="title"
          placeholder="title (Optional)"
          v-if="note.title"
        >
        <div class="note-timestamp">{{ createdAt }}</div>
        <div class="note-actions">
          <button class="action-btn" @click="mode = 'expand'">
            <img src="@/assets/icons/expand.svg" alt="Expand">
          </button>
          <button class="action-btn" @click="mode = 'open'">
            <img src="@/assets/icons/open-in-new.svg" alt="Open in new">
          </button>
        </div>
      </div>

      <Textarea
        class="note-text"
        v-model:value="tmpNote.description"
        placeholder="Enter description"
      />
      <div style="display: flex; flex-direction: row; gap: 8px;">
        <Button @click="saveNote" :size="'default'">
          Save
        </Button>
        <Button @click="cancel" :size="'default'" :type="'secondary'">
          Cancel
        </Button>
      </div>
    </div>
    <div class="note note-updte-mode" v-if="mode == 'update'">
      <div class="note-header">
        <div class="note-timestamp">
          <p>
            {{ createdAt }}
          </p>
          <div style="
            width: 4px;
            height: 4px;
            background-color: rgb(128, 128, 128);
            border-radius: 50%;
          " v-if="updatedAt && updatedAt != ''" />
          <p v-if="updatedAt && updatedAt != ''">
            Updated {{ updatedAt }}
          </p>
        </div>
        <div class="note-actions">
          <button class="action-btn" @click="mode = 'expand'">
            <img src="@/assets/icons/expand.svg" alt="Expand">
          </button>
          <button class="action-btn" @click="mode = 'open'">
            <img src="@/assets/icons/open-in-new.svg" alt="Open in new">
          </button>
        </div>
      </div>

      <Textarea
        class="note-text"
        v-model:value="tmpNote.description"
        placeholder="Enter description"
      />
      <div style="display: flex; flex-direction: row; gap: 8px;">
        <Button @click="updateNote" :size="'default'">
          Update
        </Button>
        <Button @click="cancel" :size="'default'" :type="'secondary'">
          Cancel
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  computed,
  reactive,
  ref,
} from 'vue';
import { NoteData, emptyNote } from '@/rest-api/companies/assets';
import Button from '@/components/ui/ButtonComponent.vue';
import Textarea from '@/components/ui/TextareaComponent.vue';
import moment from 'moment';
import { CompanyController } from '@/controllers/company/types';

export default defineComponent({
  name: 'NoteComponent',
  components: {
    Button,
    Textarea,
  },
  props: {
    note: {
      type: Object as PropType<NoteData>,
      required: true,
    },
    company: {
      type: Object as PropType<CompanyController>,
      required: true,
    },
  },
  setup(props) {
    const expand = ref(false);
    const mode = ref('');
    const tmpNote = reactive({ ...props.note });
    const toggleExpand = (e: MouseEvent) => {
      e.preventDefault();
      expand.value = !expand.value;
    };
    const createdAt = computed(() => {
      if (props.note.createdAt) {
        const date = moment(props.note.createdAt);
        // Format the date
        const formattedDate = date.format('ddd, MMM DD, YYYY');
        return formattedDate;
      }
      return null;
    });
    const updatedAt = computed(() => {
      if (props.note.updatedAt) {
        const date = moment(props.note.createdAt || '');
        // Format the date
        const formattedDate = date.format('MMM DD, YYYY');
        return formattedDate;
      }
      return null;
    });
    const saveNote = () => {
      if (props.note.id
      && (tmpNote.title !== props.note.title || tmpNote.description !== props.note.description)) {
        props.company.saveNote(props.note.id, tmpNote);
        Object.assign(props.note, tmpNote);
      }
      mode.value = '';
    };
    const cancel = () => {
      Object.assign(tmpNote, { ...props.note });
      mode.value = '';
    };
    const updateNote = () => {
      if (props.note.id
      && (tmpNote.title !== props.note.title || tmpNote.description !== props.note.description)) {
        props.company.updateNote(props.note.id, tmpNote);
        Object.assign(props.note, tmpNote);
      }
      mode.value = '';
    };
    return {
      createdAt,
      updatedAt,
      expand,
      tmpNote,
      mode,
      toggleExpand,
      saveNote,
      cancel,
      updateNote,
    };
  },
});
</script>

<style>
.notes > .notes-section > .note-list .note-card {
  width: 50%;
  position: relative;
}
.notes .note {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 16px;
  background: #F4F6FF;
  border-radius: 8px;
}
.notes .note-card .note-edit-mode {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 16px;
  background: #F4F6FF;
  border-radius: 8px;
}
.notes .note-card .note-header {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
}

.notes .note-card .note-header .title {
  background: none;
  outline: none;
  border: none;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  color: #131313;
}

.notes .note-card .note-header > .note-timestamp {
  color: #B3B3B3;
  font-size: 14px;
  font-weight: 600;
}

.notes .note-card .note-header > .note-actions {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  gap: 8px;
}

.notes .note-card .note-header > .note-actions > .action-btn {
  cursor: pointer;
  background: none;
  border: none;
}

.notes .note-card .note-text {
  font-size: 16px;
  font-weight: 400;
  color: var(--theme-text-color-2);
}

.notes .note > .show-more {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-link-color);
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
}
</style>

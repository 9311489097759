<template>
  <div>
    <Card id="notes">
      <div class="notes">
        <div class="notes-section">
          <Input placeholder="New note" :clickable="true"
            v-on:handle-click="handleClickNewNote"
            :value="description" v-if="addNewNote === false"/>
          <NoteEditComponent :note="note" v-if="addNewNote === true"/>
          <div class="note-list" v-if="notes.length > 1">
            <Note v-for="(note, index) in notes.slice(0, 2)"
            :company="company"
            :key="index"
            :note="note" />
          </div>
          <div style="display: flex; gap: 24px; align-items: center;">
            <Button @click="submitNewNote" :size="'default'" :visuallyDisabled="!addNewNote">
              Add new note
            </Button>
            <p style="font: Gilroy; font-size: 12px; font-weight: 400; color: #808080;">
              By default your notes are visible only to you.
            </p>
          </div>
        </div>
        <div class="notes-section" v-if="notes.length == 1">
          <Note :note="notes[0]" :company="company"/>
        </div>
      </div>
    </Card>
    <AddNewNoteModal :company="company"/>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  PropType,
} from 'vue';
import { useStore } from 'vuex';
import * as api from '@/rest-api/companies';

import Card from '@/components/ui/CardComponent.vue';
import Input from '@/components/ui/InputComponent.vue';
import Button from '@/components/ui/ButtonComponent.vue';
import Note from '@/views/company/note/NoteComponent.vue';
import AddNewNoteModal from '@/components/modals/AddNewNoteModal.vue';
import { SHOW_MODAL } from '@/store/actions/application';
import { NoteData, emptyNote } from '@/rest-api/companies/assets';
import { CompanyController } from '@/controllers/company/types';
import NoteEditComponent from './NoteEditComponent.vue';
import NoteTitle from './NoteTitle.vue';

export default defineComponent({
  name: 'CompanyNotes',
  components: {
    Card,
    Button,
    Input,
    Note,
    NoteEditComponent,
    AddNewNoteModal,
  },
  props: {
    company: {
      type: Object as PropType<CompanyController>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const description = ref('');
    const loading = ref(false);
    const notes: NoteData[] = reactive([]);
    const note: NoteData = reactive({ ...emptyNote });
    const addNewNote = ref(false);
    const showAddNewNoteModal = ref(false);
    const handleClickNewNote = () => {
      addNewNote.value = true;
    };
    const submitNewNote = () => {
      loading.value = true;
      if (addNewNote.value) {
        addNewNote.value = false;
        note.currency = props.company.data.currency;
        note.symbols.push(props.company.data.symbol);
        console.log(note);
        props.company.addNote(note);
        notes.push(note);
        Object.assign(note, emptyNote);
        loading.value = false;
      } else {
        showAddNewNoteModal.value = true;
        store.commit(SHOW_MODAL, 'add-new-note-modal');
      }
    };
    const getRecentNotes = () => {
      loading.value = true;
      props.company.getRecentNotes().then((payload: NoteData[]) => {
        Object.assign(notes, payload);
        loading.value = false;
      });
    };
    onMounted(() => {
      getRecentNotes();
    });
    return {
      note,
      notes,
      description,
      addNewNote,
      showAddNewNoteModal,
      handleClickNewNote,
      submitNewNote,
    };
  },
});
</script>

<style scoped>
.notes {
  display: flex;
  gap: 16px;
}

.notes > .notes-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.notes > .notes-section > input {
  width: 100%;
}

.notes > .notes-section > .note-list {
  width: 100%;
  display: flex;
  gap: 16px;
}
</style>

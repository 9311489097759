import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["checkbox", {
      'checkbox-checked': _ctx.innerValue,
      'checkbox__big': _ctx.type === 'big',
      'checkbox__medium': _ctx.type === 'medium',
    }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.innerValue = !_ctx.innerValue))
  }, null, 2))
}
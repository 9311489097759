import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/close-badge.svg'


const _withScopeId = n => (_pushScopeId("data-v-3e0f8364"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "badge-label" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "TopFunds"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["badge", {
    'primary_badge': _ctx.type === 'primary',
    'secondary_badge': _ctx.type === 'secondary',
    'white_badge': _ctx.type === 'white',
    'size_default': _ctx.size === 'default',
    'size_medium': _ctx.size === 'medium',
    'size_small': _ctx.size === 'small',
  }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", null, _toDisplayString(_ctx.label), 1),
      (_ctx.close)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
<template>
  <div class="inline-form-input">
    <p>{{ label }}</p>
    <input type="text" v-model="innerValue"
    name=""
    :style="{'cursor': clickable ? 'pointer' : 'auto'}"
    @click="handleClick" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'FormInputComponent',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: false,
    },
    clickable: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['update:value', 'handleClick'],
  setup(props, { emit }) {
    const innerValue = computed({
      get() {
        if (props.field && props.field === 'forecastField' && typeof (props.value) === 'number') {
          const val = Math.round(props.value * 10) / 10;
          return val;
        }
        return props.value;
      },
      set(newValue) {
        emit('update:value', newValue);
      },
    });
    const handleClick = (e: MouseEvent) => {
      if (!props.clickable) {
        return;
      }
      e.preventDefault();
      emit('handleClick');
    };

    return {
      innerValue,
      handleClick,
    };
  },
});
</script>

<style scoped>
.inline-form-input {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.inline-form-input p {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #131313;
  letter-spacing: 2%;
}
input {
  background: #F4F6FF;
  padding: 9px 16px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: #131313;
}
</style>
